<template>
  <v-footer padless elevation="4">
      <v-card flat tile class="stonewood_primary darken-2 py-10  text-center">
        <v-card-text>
                <div class="white--text">
                  <h5>Notes & Disclosure:</h5>
                  <p>
                    1  Current taxes based on maintaining your existing Qualified Account. Qualified accounts are funded using pre-tax dollars, and taxes are generally owed on principal and interest when the funds are distributed.
                  </p>
                  <p>
                    2  Adjustment modeled is based on Roth conversion of funds. Roth accounts are funded with post-tax dollars and withdrawals of principal and interest are generally distributed tax-free. This is not a complete discussion of the benefits, limitations and requirements of Roth accounts or tax-free savings vehicles.
                  </p>

                  <p class="text-caption font-italic">This analysis is designed to provide general information only. Pursuant to IRS Circular 230 and any license limitations of the licensed professional presenting this material, this analysis is not intended to provide tax or legal advice. Be sure to speak with a qualified professional in these areas before making any decisions about your personal situation. This analysis does not consider every product or feature of tax-deferred or Roth accounts and is for illustrative purposes only. It should not be deemed a representation of past or future results, and is no guarantee of return or future performance. Your tax bracket may be lower or higher in retirement, unlike this hypothetical example. Every effort has been made to ensure the accuracy of these numbers. RMD calculation data gathered from Stonewood RMD calculation software based on IRS guidelines and tables, and is hypothetical only. Your actual RMDs are determined by a variety of factors.</p>
                  <p class="text-caption font-italic">
                  {{this.$store.state.company.lead_capture_disclosure }}
                  </p>
                  <p class="text-caption font-italic">
                    By submitting your contact information, you consent to be contacted by a Financial Professional regarding retirement income strategies.
                  </p>

                  <p class="text-center mt-5"> 
                    ©{{ new Date().getFullYear() }}  All rights reserved. 
                  </p>
                </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
  </v-footer>
</template>
<script>
export default {
  mounted() {
      this.disclaimer = this.$store.state.company.lead_capture_disclosure 
  },
  name: "appFooter",
  data: () => ({
    disclaimer: "",
  }),
  props: {
    companyName: {
      type: String,
      default: "Stonewood Financial Solutions",
    },
    companyPhone: {
      type: String,
      default: "(502) 588-7155",
    },
    companyUrl: {
      type: String,
      default: "http://www.stonewood.com",
    },
  },
};
</script>