<template>

  <v-form @submit.prevent="sendProposal" v-model="valid">
        
      <v-row>
        <v-col cols="12" lg="8" offset-sm="1" sm="10" offset-lg="2"> 
          <v-card :elevation=" formFocus ? 12 : 6"  class="form-card">
            <v-card-title class="text-h3 wbreak"
              >Potential Tax Bill Calculator</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="" >
                <v-col cols="12">
                   <div class="d-flex">
                  <v-icon class="pb-6 input-prep-icon">mdi-calendar-account</v-icon>                  
                  <vuetify-money
                      ref="formAge"
                      class="
                        flex-grow-1
                        ml-3
                        vuetify-money-input
                        text--stonewood_primary
                      "
                      outlined
                      :rules="ageRules"
                      v-model="age"
                      v-bind:label="labelAge"
                      v-bind:placeholder="placeholderAge"
                      v-bind:readonly="readonly"
                      v-bind:disabled="disabled"
                      v-bind:clearable="clearable"
                      v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                      v-bind:options="optionsAge"
                      v-bind:properties="propertiesAge"
                    >
                    </vuetify-money>
                 </div>
                 
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12">
                  <div class="d-flex">
                    <v-icon class="pb-6 input-prep-icon">mdi-account-cash</v-icon>
                    <vuetify-money
                      class="
                        flex-grow-1
                        ml-3
                        vuetify-money-input
                        text--stonewood_primary
                      "
                      required
                      outlined
                      v-model="ira_value"
                      v-bind:label="label"
                      v-bind:placeholder="placeholder"
                      v-bind:options="options"
                      v-bind:properties="properties"
                      :rules="ira_valueRules"
                    >
                    </vuetify-money>
                  </div>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col>
                   <v-slider 
                    class="mt-0"                   
                    prepend-icon="mdi-brightness-percent"
                    append-icon="mdi-percent-outline"
                    label="Average Tax Rate"
                    :readonly= useTaxRateDefault
                    v-model="tax_rate"
                    dense 
                    max="40" 
                    min="15"
                    step="5"
                    value="25"
                    thumb-label="always"
                    thumb-size="23"
                    ticks
                    >
                  </v-slider>
                  <v-checkbox
                    class="mt-n3 mb-1"
                    @change="taxRateToDefault"
                    v-model="useTaxRateDefault"
                    label=" Don't Know? Use the default tax rate (25%)"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="12" lg="6">
                  <v-text-field
                    ref="formFirstName"
                    prepend-icon="mdi-account"
                    v-model="first_name"
                    :rules="nameRules"
                    label="First name *"
                    required
                    color="black"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-model="last_name"
                    :rules="nameRules"
                    label="Last name *"
                    required
                    color="black"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    prepend-icon="mdi-email"
                    @keydown.space.prevent
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail *"
                    required
                    color="black"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    prepend-icon="mdi-cellphone"
                    v-model="phone"
                    label="Phone Number"
                    color="black"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-col class="text-center">
                <v-btn
                  color="stonewood_primary"
                  class="my-1"
                  type="submit"
                  :disabled="!valid"
                  rounded
                  large
                >
                  See My Bill
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>      
  </v-form>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**/
Vue.use(VueAxios, axios);

var qs = require("qs");

export default {

  components: {},

  name: "CalculationForm",
  props: {
    formFocus: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    numberRule: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 20 && v <= 90) return true;
      return 'Age has to be between 20 and 90';
    },
  
    label: "Current IRA or 401(k) Value*",
    labelAge: "Enter Current Age or Age of Oldest Spouse *",    
    placeholderAge: "00",
    advisorId:"",
    placeholder: "1000",
    useTaxRateDefault: false,
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "en-US",
      suffix: "",
      length: 11,
      precision: 0,
    },
        iraRules: [
      v => !!v || "This field is required"

    ],
    optionsAge: {
      locale: "en-US",
      suffix: "",
      length: 2,
      precision: 0,
    },
    properties: {
      hint: "Enter amount",
      // You can add other v-text-field properties, here.
    },
    propertiesAge: {
      hint: "Enter Current Age or Age of Oldest Spouse",
      // You can add other v-text-field properties, here.
    },
    activePicker: null,
    menu: false,
    valid: false,
    isTaxable: false,
    dob: null,
    first_name: "",
    last_name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => !/\//.test(v) || 'Invalid name', 

    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(v) ||
        "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone Number is required",
      (v) =>
        !v ||
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) ||
        "Phone number must be valid",
    ],
    age: "",
    ageList: [
       1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ],
    ageRules: [
      v => !!v || "This field is required",
    v => ( v && v >= 20 ) || "Age should be greater than 20",
    v => ( v && v <= 91 ) || "Age should not be above 90",

    ],
    ira_value: "",
    ira_valueRules: [
      v => !!v || "This field is required",
      (v) => !!v || "This amount is required for the calculation",
      (v) => parseFloat(v) > 0 || "Please enter a value greater than 0"

    ],
    tax_rate: "",
    LiabilityPercentages: ["15%", "20%", "30%"],
    tax_rateRules: [
      (v) => !!v || "This Percentage is required for the calculation",
    ],
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    formFocus: function(){
      this.focunOnForm();
    }
  },
  methods: {
    // focunOnForm(){
    //     //this.$refs["formFirstName"].focus();     
    // },
    focunOnForm() {
    this.$nextTick(() => {
      // Find the actual input element inside the custom vuetify-money component
      const ageInput = this.$refs.formAge.$el.querySelector('input');
      if (ageInput) {
        ageInput.focus(); // Focus the input element
      }
    });
  },
    taxRateToDefault(){
      if(this.useTaxRateDefault){
         this.tax_rate = 25;
      }else{
        this.tax_rate = 15;
      }
    },
    sendProposal() {
      this.advisorId = this.$route.query.u;
      var userDobYear = parseInt(new Date().getFullYear()) - parseInt(this.age);
      var currentDob = userDobYear + "-01-01";
      var data = qs.stringify({
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
        email: this.email,
        dob: currentDob,
        ira_value: this.ira_value,
        tax_rate: this.tax_rate,
        isTaxable: false,
        advisorId: this.$route.query.u,
      });
      var config = {
        method: "post",
                url: 'https://app.stonewoodfinancial.com/scripts/proposals/rmd/tax-burden-lead.php',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      var self = this;
      axios(config)
        .then(function (response) {
          console.log("entro con: " + response);
          self.$store.state.user.taxPaid = response.data.totalTax;   
          self.$store.state.user.totalTaxesConversion = response.data.totalTaxesConversion;    
               
          self.$store.state.user.age = self.age;
          self.$store.state.user.taxDeferred = self.ira_value;
          self.$store.state.user.taxRate = self.tax_rate;
          self.$store.state.user.first_name = self.first_name;
          self.$store.state.user.last_name = self.last_name;
          self.$store.state.user.email = self.email;
          self.$store.state.user.advisorId = self.advisorId;
          self.$store.state.user.proposalId = response.data.encryptedId;
          
          self.$router.push({ path: "/analysis" });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>